<template>
  <div>
    <ekle-headers
      :title="'Güzergah Bilgisi'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Ürün güzergah bilgisini.'">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col>
                  <v-multiselect-listbox
                    v-model="form.taglar"
                    :options="taglar"
                    :reduce-display-property="(option) => option.baslik"
                    :reduce-value-property="(option) => option.k_no"
                    :show-select-all-buttons="true"
                    search-options-placeholder="Tag Arama yap..."
                    selected-options-placeholder="Seçilen Tag Arama yap..."
                  >
                  </v-multiselect-listbox>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Kaydet ve Devam et' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block>
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import '@/libs/vue-multiselect';
export default {
  components: {
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
  },
  props: {
    taglar: {
      type: Array,
      required: true,
      default: [],
    },
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit');
    },
  },
};
</script>

<style lang="scss">
@import 'vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss';
.msl-multi-select {
  display: inline-flex;
  min-height: 400px;
  max-height: 600px;
  width: 100%;
  .msl-search-list-input {
    padding: 10px;
    border-radius: 0;
    border: 1px solid #ebe9f0;
    &:focus {
      border-color: 1px solid #747376;
      outline: 0;
    }
  }
  .msl-searchable-list__items {
    border: 1px solid #ebe9f0;
    .msl-searchable-list__item {
      padding: 10px 10px;
      cursor: pointer;
      border-bottom: 1px solid #ebe9f0;
    }
  }
}
</style>
